import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  height: calc(100vh - 170px);
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const Tickets = styled.div`
  width: 440px;
  display: flex;
  align-items: center;

  img + img {
    margin-left: 16px;
  }
  img {
    height: 100px;
  }
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Raleway';
  font-size: 20px;
  color: #5f6368;

  span {
    width: 300px;
  }
  span + span {
    margin-top: 16px;
  }
`;

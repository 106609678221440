import React from 'react';

import { Container } from './styles';

import ShareInnovation from '../../assets/vetor-innovation.png';

const Orbits: React.FC = () => {
  return (
    <Container>
      <img src={ShareInnovation} alt="Mural da Inovação" />
      <div className="info">
        <span>A sua ideia está sendo analizada.</span>
        <span>
          Ela será colocada devidamente na órbita certa e estará em conexão com
          as melhores empresas que buscam captar as suas inovações.
        </span>
      </div>
    </Container>
  );
};

export default Orbits;

import styled from 'styled-components';

export const Header = styled.header`
  width: 100%;
  height: 150px;

  border-radius: 10px;

  background: #fff;

  display: flex;
  align-items: center;

  div.avatar {
    > img {
      margin-left: 20px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  div.data {
    width: 100%;
    margin-left: 16px;
    padding: 10px 20px 10px 10px;

    div.top {
      > span {
        color: #5f6368;
        font-family: 'Raleway';
        font-size: 20px;
      }
      h2 {
        margin-bottom: 10px;
        color: #001a69;
        font-family: 'Raleway';
        font-size: 30px;
      }
      border-bottom: 2px solid #ff8600;
    }

    div.bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;

      margin-top: 16px;

      div.contato {
        display: flex;
        justify-content: center;
        flex-direction: column;
        > span {
          color: #5f6368;
          font-family: 'Raleway';
          font-size: 20px;
        }
        span + span {
          margin-top: 5px;
        }
      }

      div.ideia {
        display: flex;
        align-items: center;

        > img {
          width: 40px;
          height: 50px;
        }
        span {
          margin-left: 10px;
          color: #c91216;
          font-family: 'Raleway';
        }
      }
    }
  }
`;

export const Body = styled.div`
  margin-top: 30px;

  width: 700px;
  height: 175px;

  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  background: #fff;

  padding: 20px;
  width: 60%;

  > div img {
    width: 100px;
    height: 100px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 16px;

    span {
      color: #5f6368;
      font-family: 'Raleway';
      font-size: 25px;
    }

    p {
      margin-left: 32px;

      color: #001a69;
      font-family: 'Raleway';
      font-size: 25px;
    }
  }
`;

export const Tickets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div.tickets-orbitas {
    height: 100%;
    display: flex;
    align-items: center;
    img + img {
      margin-left: 16px;
    }
    img {
      height: 100px;
    }
    display: flex;
    align-items: center;
  }
  a {
    background: #001a69;
    color: #fff;
    display: block;
    padding: 2px 10px;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 16px;
    text-decoration: none;
    width: 200px;

    text-align: center;
    font-family: 'Raleway';
  }
`;

export const Footer = styled.footer`
  margin-top: 20px;

  width: 500px;
  height: 70px;

  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    font-family: 'Raleway';
    font-size: 20px;
    color: #001a69;

    b {
      color: #ff8600;
    }
  }
`;

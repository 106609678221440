import React from 'react';
import { FaRegUser } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import DesktopHeader from '../../components/DesktopHeader';

import LogoConecta from '../../assets/logoconecta.png';
import Orbitas from '../../assets/orbitas.png';
import OrbitasRight from '../../assets/orbitas-right.png';
import Rocket from '../../assets/rocket.png';
import Clouds from '../../assets/clouds.png';
import Dots from '../../assets/bolinhas.png';

import IconOrbits from '../../assets/icons/orbitas.svg';
import IconWall from '../../assets/icons/mural.svg';
import IconContact from '../../assets/icons/contato.svg';

import { version } from '../../../package.json';

import { Container, Panel, Content, Footer } from './styles';

const Dashboard: React.FC = ({ children }) => {
  const location = useLocation();

  return (
    <Container>
      <DesktopHeader />
      <Panel>
        <section>
          <NavLink to="/perfil">
            <FaRegUser size={24} />
            <b>Meu Perfil</b>
          </NavLink>
          <NavLink to="/orbits">
            <img src={IconOrbits} alt="Órbitas IncaaS" />
            <b>Órbitas IncaaS</b>
          </NavLink>
          <NavLink to="/innovation">
            <img src={IconWall} alt="Mural da Inovação" />
            <b>Mural da Inovação</b>
          </NavLink>
          <NavLink to="/contact">
            <img src={IconContact} alt="Contato IncaaS" />
            <b>Contato IncaaS</b>
          </NavLink>
        </section>
        <div>
          <span>
            www.
            <b>incaas</b>
            .com.br
          </span>
          <img src={Dots} alt="Incaas url" />
          <small>{`v. ${version}`}</small>
        </div>
        <footer>
          <img src={LogoConecta} alt="LogoConecta" />
        </footer>
      </Panel>
      <Content>
        <div>{children}</div>
      </Content>
      <Footer
        isProfile={location.pathname === '/perfil'}
        isOrbits={location.pathname === '/orbits'}
      >
        <div>
          <span>Desenvolvido Estrategicamente por:</span>
          <img src={LogoConecta} alt="LogoConecta" />
        </div>
        <img src={Rocket} alt="Rocket" />
        {location.pathname === '/perfil' && (
          <img className="orbitas" src={Orbitas} alt="Órbitas" />
        )}
        {location.pathname === '/orbits' && (
          <img className="orbitas" src={OrbitasRight} alt="Órbitas" />
        )}
        <img src={Clouds} alt="Clouds" />
      </Footer>
    </Container>
  );
};

export default Dashboard;

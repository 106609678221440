import React from 'react';

import TicketsOrbitas from '../../assets/tickets-orbitas.png';
import DotsVertical from '../../assets/bolinhas-vertical.png';

import { Container, Tickets, Text } from './styles';

const Orbits: React.FC = () => {
  return (
    <Container>
      <div>
        <Tickets>
          <img src={DotsVertical} alt="Orbitas IncaaS" />
          <img src={TicketsOrbitas} alt="Tickets Orbitas" />
        </Tickets>
        <Text>
          <span>Estamos sintonizando as melhores órbitas. </span>
          <span>Em breve teremos mais informações sobre elas.</span>
        </Text>
      </div>
    </Container>
  );
};

export default Orbits;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-evenly;

  position: relative;
  img {
    height: 400px;
  }

  div.info {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    span + span {
      margin-top: 30px;
    }
    span {
      font-family: 'Raleway';
      font-size: 20px;
      color: #5f6368;
    }
  }
`;

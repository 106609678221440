import React from 'react';

import { Container, ContentLeft, ContentRight } from './styles';

import vetorContact from '../../assets/vetor-contact.png';

const Contact: React.FC = () => {
  return (
    <Container>
      <ContentLeft>
        <div className="contact">
          <section>
            <span>
              <b>E-mail</b>:
            </span>
            <button type="button">contato@incaas.com.br</button>
          </section>
          <section>
            <span>
              <b>Whatsapp</b>:
            </span>
            <a
              href="https://chat.whatsapp.com/Fb7J3vzimL8IwjhowpO25f"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entrar no Grupo
            </a>
          </section>
          <section>
            <span>
              <b>Instagram</b>:
            </span>
            <a
              href="https://www.instagram.com/incaas.oficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Seguir @incaas.oficial
            </a>
          </section>
        </div>
        <span>
          Em breve, esta plataforma será o único meio de interação entre os que
          já estiverem em órbita.
        </span>
      </ContentLeft>
      <ContentRight>
        <div className="instructions">
          <span>Tenha uma comunicação direta conosco.</span>
          <span>A partir de agora estamos conectados.</span>
        </div>
        <div className="imagem">
          <img src={vetorContact} alt="Contato IncaaS" />
        </div>
      </ContentRight>
    </Container>
  );
};

export default Contact;

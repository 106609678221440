import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div<{ formOpen: boolean }>`
  grid-template-columns: 60% 40%;
  /* grid-template-rows: 115vh; */
  height: 100vh;

  display: grid;
  align-items: stretch;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    /* grid-template-rows: 110vh; */

    div.content-signup {
      display: none;
    }
    ${({ formOpen }) =>
      formOpen &&
      css`
        div.content-signup {
          display: unset;
        }
        div.background {
          display: none;
        }
      `};
  }
`;

export const Background = styled.div`
  display: flex;

  text-align: center;
  align-items: center;
  flex-direction: column;

  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }

  div.header {
    height: 200px;
    h1 {
      width: 370px;
      color: #01af4e;
      margin: 20px 0 20px 0;
    }
    img {
      height: 9px;
      width: 60px;
      margin-bottom: 30px;
    }
    a,
    img.logo-incaas {
      display: none;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;

      a {
        display: unset;
        position: absolute;
        top: 15px;
        left: 0;
        img {
          height: 30px;
        }
      }

      img.logo-incaas {
        display: unset;
        height: 68px;
        width: 131px;
        margin: 60px 0 0 0;
      }
      h1 {
        margin: 20px 0 5px 0;
        font-weight: 700;
        font-size: 20px;
        width: 300px;
        line-height: 20px;
      }
    }
  }

  div.body {
    width: 100%;
    padding: 0 15px;
    p {
      text-align: center;
      color: #001a69;
      font-weight: 700;
    }

    p + p {
      margin-top: 10px;
    }
    button {
      display: none;
    }

    @media (max-width: 768px) {
      width: 306px;
      padding: 0;

      p {
        text-align: center;
        color: #001a69;
        font-weight: 700;
        font-size: 11px;
      }

      p + p {
        margin-top: 10px;
      }

      button {
        display: unset;
        background: #01af4e;
        &:hover {
          background: ${shade(0.1, '#01AF4E')};
        }
      }
    }
  }

  span {
    margin: 10px 0 10px 0;
  }

  div.footer {
    display: flex;
    width: 100%;
    height: 100%;

    > img {
      height: 250px;
      width: 480px;
      align-self: flex-end;
      position: absolute;
      left: -25px;
    }
    div.incaas {
      display: none;
    }
    @media (max-width: 768px) {
      position: relative;

      div.incaas {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        bottom: 15px;
        left: 15px;
        img.bolinhas {
          height: 9px;
          width: 60px;
        }
        a {
          text-decoration: none;
          color: #1c2d3c;
          font-family: 'Raleway';
          font-size: 13px;
          &:hover {
            color: ${shade(0.3, '#fff')};
          }
          b {
            font-size: 20px;
          }
        }
      }
      > img {
        position: absolute;
        width: 142px;
        height: 110px;
        left: auto;
        right: 0;
      }
    }
  }
`;

import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';
import logoBolas from '../../assets/bolinhas.png';
import logoCadastro from '../../assets/vetor_cadastro.png';
import logoIncaas from '../../assets/logo.png';
import backIcon from '../../assets/icons/back.svg';

import { Container, Background } from './styles';
import Content from './Content';
import Button from '../../components/Button';

interface SignUpFormData {
  name: string;
  avatar?: string;
  email: string;
  phone_number: string;
  password: string;
  file_idea: File;
  file_idea_registered: boolean;
  type_registration: string;
  doubt?: string;
}

interface FileIdea {
  file: File;
  isRegistered: boolean;
}

const SignIn: React.FC = () => {
  const [openForm, setOpenform] = useState(false);
  const renderForm = useCallback(() => {
    setOpenform(!openForm);
  }, [openForm]);

  return (
    <Container formOpen={openForm}>
      <Content />
      <Background className="background">
        <div className="header">
          <div className="back">
            <Link to="/">
              <img src={backIcon} alt="Voltar" />
            </Link>
          </div>
          <div className="logo">
            <img
              className="logo-incaas"
              src={logoIncaas}
              alt="Plataforma IncaaS"
            />
          </div>
          <h1>Formulário de Adesão à Plataforma IncaaS</h1>
          <img className="logoBolas" src={logoBolas} alt="" />
        </div>
        <div className="body">
          <p>
            Nós, da Plataforma IncaaS, entendemos que o maior desafio em
            transformar sua ideia em um P2S2 (Produto | Processo | Serviço |
            Startup) está entre a Validação de Conceitos, feita por você, e a
            respectiva Verificação em Mercado, que não poderá ser feita por
            você.
          </p>
          <p>
            Para te ajudar a "enxergar essa parede", criamos este formulário,
            através do qual compreenderemos - junto a você - o potencial de sua
            ideia. A partir daqui, trabalharemos na concepção do melhor modelo,
            de modo a encaixá-lo como Produto, Processo, Serviço ou, sendo bem
            radical, como uma Startup.
          </p>
          <p>
            Após o preenchimento deste formulário, entraremos em contato por
            Whatsapp para marcarmos um bate-papo.
          </p>

          <p>
            Seja bem-vindo(a) &agrave; IncaaS. Desde 2019, Iluminando Ideias!
          </p>
          <Button type="submit" onClick={renderForm}>
            REGISTRAR
          </Button>
        </div>

        <div className="footer">
          <div className="incaas">
            <img className="bolinhas" src={logoBolas} alt="Órbitas" />
            <a href="https://www.incaas.com.br">
              www.
              <b>incaas</b>
              .com.br
            </a>
          </div>
          <img src={logoCadastro} alt="" />
        </div>
      </Background>
    </Container>
  );
};

export default SignIn;

import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;

  justify-content: space-evenly;

  span {
    font-family: 'Raleway';
  }
`;
export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  section {
    width: 300px;
    height: 60px;
    background: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 10px;

    & + section {
      margin-top: 20px;
    }
    span {
      color: #001a69;

      & + a,
      & + button {
        margin-top: 5px;
      }
    }

    a,
    button {
      background: #001a69;
      color: #fff;
      display: block;
      border: 0;
      padding: 2px 10px;
      box-sizing: border-box;
      border-radius: 12px;
      margin-top: 16px;
      text-decoration: none;
      width: 200px;

      text-align: center;
      font-family: 'Raleway';

      &:hover {
        background: ${shade(0.3, '#001a69')};
      }
    }
  }

  > span {
    width: 250px;
  }
`;
export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  div.instructions {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    span + span {
      margin-top: 30px;
    }
  }
  div.imagem {
    margin-top: 20px;
    img {
      width: 500px;
    }
  }
`;

import styled from 'styled-components';

import { AiFillCaretDown } from 'react-icons/ai';
import { FiPower } from 'react-icons/fi';

export const Logo = styled.div`
  grid-area: Logo;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 0;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  border-right: 2px solid #e4e7e9;
  border-bottom: 2px solid #e4e7e9;

  background: #fff;
`;

export const Header = styled.header`
  grid-area: Header;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: #fff;

  border-bottom: 2px solid #e4e7e9;

  div {
    display: flex;
    margin-right: 16px;
    button {
      background: none;
      border: 0;
      outline: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      /* min-width: 90px; */
      /* min-height: 100%; */

      &:hover {
        color: #fff;
      }

      &.active {
        border-bottom: 2px solid #fff;
      }

      & + button {
        margin-left: 16px;
      }
    }
  }
`;

export const LogoutIcon = styled(FiPower)`
  color: white;
  background: #cfcbcb;
  border-radius: 50px;
  padding: 8px;
`;

export const ProfileCircle = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--color-icons);
`;

export const CaretDownIcon = styled(AiFillCaretDown)`
  width: 16px;
  height: 16px;
`;

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Perfil from '../pages/Perfil';
import Orbits from '../pages/Orbits';
import Innovation from '../pages/WallInnovation';
import Contact from '../pages/Contact';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/perfil" component={Perfil} isPrivate />
    <Route path="/orbits" component={Orbits} isPrivate />
    <Route path="/innovation" component={Innovation} isPrivate />
    <Route path="/contact" component={Contact} isPrivate />
  </Switch>
);

export default Routes;

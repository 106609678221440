import React, { useRef, useCallback } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import logoImg from '../../assets/logo.png';
import vetorLogin from '../../assets/vetor_login.png';
import logoConecta from '../../assets/logoconecta.png';
import logoBolas from '../../assets/bolinhas.png';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrros from '../../utils/getValidationErrors';

import { Container, Content, Background } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { signIn } = useAuth();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/perfil');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrros(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais.',
        });
      }
    },
    [addToast, signIn, history],
  );
  return (
    <Container>
      <Background className="background">
        <div>
          <span className="span-welcome">Bem vind@</span>
          <img src={logoBolas} alt="" />
          <h1>Plataforma IncaaS</h1>
          <span className="span-text">
            Validação de Conceitos, feita por você, e a
            <br />
            respectiva Verificação em Mercado
          </span>
        </div>
        <footer>
          <img src={vetorLogin} alt="IncaaS" />
          <a href="https://www.incaas.com.br">
            www.
            <b>incaas</b>
            .com.br
          </a>
        </footer>
      </Background>
      <Content>
        <img src={logoImg} alt="IncaaS" />
        <div className="welcome-mobile">
          <span className="span-welcome">Bem vind@</span>
          <img src={logoBolas} alt="" />
          <h1>Plataforma IncaaS</h1>
          <span className="span-text">
            Validação de Conceitos, feita por você, e a
            <br />
            respectiva Verificação em Mercado
          </span>
        </div>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input name="email" icon={FiMail} placeholder="E-mail" />

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />
          <div className="buttons-container">
            <Link to="/signup">Cadastre-se</Link>
            <Button type="submit">Entrar</Button>
          </div>
        </Form>
        <div className="footer">
          <section className="vetor">
            <img src={vetorLogin} alt="IncaaS" />
          </section>
          <section className="plataforma">
            <img src={logoBolas} alt="logo bolas" />
            <a href="https://www.incaas.com.br">
              www.
              <b>incaas</b>
              .com.br
            </a>
            <div>
              <span>Plataforma Desenvolvida por: </span>
              <img
                className="conecta-mapa"
                src={logoConecta}
                alt="logo conecta"
              />
            </div>
          </section>
        </div>
      </Content>
    </Container>
  );
};

export default SignIn;

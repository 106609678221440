import React, { useRef, MouseEvent } from 'react';

import { Modal, Overlay } from './styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalSignup: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const overlayRef = useRef(null);

  const handleOverlayClick = (e: MouseEvent<HTMLElement>) => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return isOpen ? (
    <Modal>
      <Overlay ref={overlayRef} onClick={handleOverlayClick} />
      {children}
    </Modal>
  ) : null;
};

export default ModalSignup;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import iconPdf from '../../assets/icon-pdf.png';
import Calendar from '../../assets/vetor-calendario.png';
import searchBanner from '../../assets/vetor-search.png';
import DotsVertical from '../../assets/bolinhas-vertical.png';
import TicketsOrbitas from '../../assets/tickets-orbitas.png';
import NoAvatar from '../../assets/sem-imagem-avatar.png';

import api from '../../services/api';

import { Header, Body, Footer, Banner, Tickets } from './styles';

interface User {
  id: string;
  name: string;
  phone_number: string;
  email: string;
  type_registration: string;
  file_idea: string;
  avatar: string;
}

const Perfil: React.FC = () => {
  const [user, setUser] = useState<User>();
  // const [downloadFile, setDownloadFile] = useState('');

  useEffect(() => {
    async function handleListData() {
      const { id } = JSON.parse(localStorage.getItem('@IncaaS:user')!);
      api.get(`/users/${id}`).then(res => {
        setUser(res.data);
      });
    }
    handleListData();
  }, []);

  // function handleDownloadFile() {
  //   api.get(`/files/${user?.file_idea}`).then(res => {
  //     setDownloadFile(res.config.url);
  //   });
  // }
  return (
    <>
      <Header>
        <div className="avatar">
          {user?.avatar ? (
            <img src={user?.avatar} alt="Avatar" />
          ) : (
            <img src={NoAvatar} alt="Avatar" />
          )}
        </div>
        <div className="data">
          <div className="top">
            <span>Bem vind@</span>
            <h2>{user?.name}</h2>
          </div>
          <div className="bottom">
            <div className="contato">
              <span>{user?.email}</span>
              <span>{user?.phone_number}</span>
            </div>
            <div className="ideia">
              <img src={iconPdf} alt="Minha idea" />
              <span>{user?.file_idea}</span>
            </div>
          </div>
        </div>
      </Header>
      <Body>
        <Banner>
          <span>Em breve você irá entrar na órbita IncaaS!</span>
          <div>
            <img src={searchBanner} alt="IncaaS" />
            <p>Estamos conectando sua ideia ao mundo IncaaS...</p>
          </div>
        </Banner>
        <Tickets>
          <div className="tickets-orbitas">
            <img src={DotsVertical} alt="Orbitas IncaaS" />
            <img src={TicketsOrbitas} alt="Tickets Orbitas" />
          </div>
          <Link to="/orbits"> Entenda como funciona</Link>
        </Tickets>
      </Body>
      <Footer>
        <img src={Calendar} alt="Calendário IncaaS" />
        <span>
          Estamos a <b> 0 dias </b>
          sem novas idéias!
        </span>
      </Footer>
    </>
  );
};

export default Perfil;

import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #001a69;
  height: 40px;
  border-radius: 7px;
  border: 0;
  width: 40%;
  color: #ffffff;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.1, '#001a69')};
  }
`;

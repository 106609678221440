import React, { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import logoIncaas from '../../assets/logo.png';
import NoAvatar from '../../assets/sem-imagem-avatar.png';

import api from '../../services/api';

import { Logo, Header, LogoutIcon, ProfileCircle } from './styles';

interface User {
  id: string;
  name: string;
  phone_number: string;
  email: string;
  type_registration: string;
  file_idea: string;
  avatar: string;
}

const DesktopHeader: React.FC = () => {
  const { signOut } = useAuth();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    async function handleListData() {
      const { id } = JSON.parse(localStorage.getItem('@IncaaS:user')!);
      api.get(`/users/${id}`).then(res => {
        setUser(res.data);
      });
    }
    handleListData();
  }, []);

  return (
    <>
      <Logo>
        <img src={logoIncaas} alt="IncaaS" />
      </Logo>
      <Header>
        <div>
          <button type="button" onClick={signOut} className="active">
            <LogoutIcon size={36} />
            {/* <span>Sair</span> */}
          </button>
          <button type="button">
            {user?.avatar ? (
              <ProfileCircle src={user?.avatar} />
            ) : (
              <ProfileCircle src={NoAvatar} />
            )}
          </button>
        </div>
      </Header>
    </>
  );
};

export default DesktopHeader;

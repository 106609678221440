import React, { useRef, useCallback, useState, ChangeEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { Container, Box } from './styles';

import logoIncaas from '../../../assets/logo.png';
import logoBolas from '../../../assets/bolinhas.png';
import logoCadastro from '../../../assets/vetor_cadastro.png';

import backIcon from '../../../assets/icons/back.svg';
import logoConecta from '../../../assets/logoconecta.png';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ModalSignup from '../../../components/ModalSignup';
import InputFile from '../../../components/InputFile';
import Select from '../../../components/Select';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrros from '../../../utils/getValidationErrors';

interface SignUpFormData {
  name: string;
  avatar?: string;
  email: string;
  phone_number: string;
  password: string;
  file_idea: File;
  file_idea_registered: boolean;
  type_registration: string;
  doubt?: string;
}

interface FileIdea {
  file: File;
  isRegistered: boolean;
}

const Content: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [isModalOpen, setModalState] = useState(false);
  const [fileIdea, setFileIdea] = useState<FileIdea>();
  const [fileError, setFileError] = useState(false);

  const toggleModal = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (fileIdea) {
          data.file_idea = fileIdea.file;
          data.file_idea_registered = fileIdea.isRegistered;
        } else {
          setFileError(true);
          return;
        }

        if (!data.doubt) {
          data.doubt = 'Nenhuma dúvida registrada';
        }

        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await api.post('/users', formData);

        history.push('/');
        window.scrollTo(0, 0);

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu logon no IncaaS!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrros(err);

          addToast({
            type: 'error',
            title: 'Algo deu errado!',
            description: 'Verifique os campos do formulário e tente novamente!',
          });

          console.log(errors);
          formRef.current?.setErrors(errors);

          return;
        }

        window.scrollTo(0, 0);
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao realizar cadastro, tente novamente.',
        });
      }
    },
    [addToast, history, fileIdea],
  );

  const handleSetFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>, isRegistered: boolean) => {
      const file = e.target.files?.[0];

      if (file) {
        setFileIdea({ file, isRegistered });
        toggleModal();
      }
    },
    [toggleModal],
  );

  const options = [
    { value: 'Pessoa Física', label: 'Pessoa Física' },
    { value: 'Grupo de trabalho', label: 'Grupo de trabalho' },
  ];

  return (
    <Container className="content-signup" error={fileError}>
      <div className="header">
        <Link to="/">
          <img src={backIcon} alt="Voltar" />
        </Link>
        <img src={logoIncaas} alt="Plataforma IncaaS" />
      </div>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input
          type="text"
          name="name"
          placeholder="Qual o seu nome completo?"
        />
        <Input type="text" name="email" placeholder="E-mail para contato:" />
        <Input
          type="text"
          name="phone_number"
          placeholder="Seu telefone / WhatsApp / Telegram"
        />
        <Input type="password" name="password" placeholder="Senha de acesso" />
        <Input
          type="password"
          name="confirmePassword"
          placeholder="Repita sua senha de acesso"
        />
        <div className="label-modal">
          {fileIdea ? (
            <label>{fileIdea.file.name}</label>
          ) : (
            <label onClick={toggleModal}>Inserir arquivo de ideia</label>
          )}
        </div>
        <Select
          name="type_registration"
          placeholder="Selecione o tipo de cadastro"
          options={options}
        />
        <Input type="text" name="doubt" placeholder="Ficou alguma dúvida?" />
        <Button type="submit">Registrar</Button>
      </Form>
      <div className="footer">
        <div className="incaas">
          <div className="desktop">
            <img className="bolinhas" src={logoBolas} alt="logo bolas" />
            <div className="plataforma">
              <span>Plataforma Desenvolvida por:</span>
              <img src={logoConecta} alt="Conecta Mapa" />
            </div>
          </div>
          <div className="mobile">
            <img className="bolinhas" src={logoBolas} alt="Órbitas" />
            <a href="https://www.incaas.com.br">
              www.
              <b>incaas</b>
              .com.br
            </a>
          </div>
        </div>
        <img className="vetor" src={logoCadastro} alt="" />
      </div>
      <ModalSignup isOpen={isModalOpen} onClose={toggleModal}>
        <Box>
          <button
            type="button"
            className="modal-close-button"
            onClick={toggleModal}
          >
            <AiOutlineClose size={24} />
          </button>
          <div className="modal-content">
            <img src={logoIncaas} alt="Plataforma IncaaS" />
            <span>
              <b>
                Antes, porém, recomendamos por garantia que registre ela na
                Plataforma{' '}
                <a
                  href="https://hoodid.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://hoodid.com
                </a>
                , parceira de longa data da IncaaS.
              </b>
            </span>
            <span>
              <b>
                Feito? Então faça aqui o upload do arquivo explicando pra gente
                o que tem em mente.
              </b>
            </span>
            <div className="label-modal">
              <Form onSubmit={() => {}}>
                <label htmlFor="file_idea_registered">
                  <b>MINHA IDEIA JÁ É REGISTRADA</b>
                  <InputFile
                    onChange={e => handleSetFile(e, true)}
                    name="file-idea"
                    id="file_idea_registered"
                  />
                </label>
                <label htmlFor="file_idea">
                  <b>ENVIAR SEM REGISTRO</b>
                  <InputFile
                    onChange={e => handleSetFile(e, false)}
                    type="file"
                    id="file_idea"
                    name="file_idea"
                  />
                </label>
              </Form>
            </div>
          </div>
          <footer>
            <img className="bolinhas" src={logoBolas} alt="Órbitas" />
            <a href="https://www.incaas.com.br">
              www.
              <b>incaas</b>
              .com.br
            </a>
          </footer>
        </Box>
      </ModalSignup>
    </Container>
  );
};

export default Content;

import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div<{ error: boolean }>`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  position: relative;

  background-color: #e5e5e5;

  @media (max-width: 768px) {
    width: 100%;
    background-color: #f4f4f4;
  }

  div.header {
    display: flex;
    justify-content: space-between;
    width: 70%;
    padding: 0 20px;

    img {
      margin: 0;
      height: 68px;
      width: 131px;
    }
    a {
      text-decoration: none;
      color: #001a69;
    }
    a img {
      height: 60px;
      width: 80px;
    }

    @media (max-width: 768px) {
      margin: 15px auto 0 auto;
      width: 100%;
      div.logo {
        a img {
          height: 60px;
          width: 50px;
        }
      }
      div.back {
        > img {
          height: 68px;
          width: 131px;
        }
      }
    }
  }

  > form {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 5px 20px 20px 20px;

    div.label-modal {
      margin-bottom: 8px;
      height: 38px;

      label {
        text-decoration: none;
        width: 100%;
        height: 100%;
        background: #fff;
        border: 1px solid #d9dee2;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        transition: background-color 0.2s;
        cursor: pointer;
        ${({ error }) =>
          error &&
          css`
            border-color: #c53030;
          `};
      }
    }

    button {
      background: #01af4e;
      color: #fff;

      &:hover {
        background: ${shade(0.1, '#01AF4E')};
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      img.bolinhas {
        display: none;
      }
      div.plataforma {
        display: none;
      }
    }
  }
  div.footer {
    width: 65%;
    div.incaas {
      div.desktop {
        display: flex;
        flex-direction: column;
        > img {
          height: 9px;
          width: 60px;
          margin-top: 30px;
        }
        .plataforma {
          margin-top: 20px;
          > img {
            height: 18px;
            width: 100px;
          }
        }
        span {
          margin-right: 30px;
          color: #001a69;
        }
      }
      div.mobile {
        display: none;
      }
    }
  }
  .footer {
    img.vetor {
      display: none;
    }
  }
  @media (max-width: 768px) {
    div.footer {
      display: flex;
      width: 100%;
      height: 15%;
      justify-content: space-around;
      align-items: center;

      img.vetor {
        display: unset;
      }

      div.incaas {
        div.desktop {
          display: none;
        }

        div.mobile {
          display: flex;
          flex-direction: column;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        img.bolinhas {
          height: 9px;
          width: 60px;
        }

        a {
          text-decoration: none;
          color: #1c2d3c;
          font-family: 'Raleway';
          font-size: 13px;
          &:hover {
            color: ${shade(0.3, '#fff')};
          }
          b {
            font-size: 20px;
          }
        }
      }
      > img {
        width: 142px;
        height: 110px;
      }
    }
  }
`;

export const Box = styled.div`
  position: relative;
  width: 80%;
  margin: 0 10%;
  padding: 50px 40px;
  border-radius: 10px;
  background-color: #fff;
  cursor: auto;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  div.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      height: 110px;
      width: 220px;
    }
    span {
      text-align: center;
      font-family: 'Raleway';
      color: #051c24;
      margin-top: 24px;
      width: 600px;
      & + span {
        margin: 24px 0px;
        width: 400px;
      }
      & a {
        font-family: 'Raleway';
        color: #051c24;
        text-decoration: none;
      }
    }

    div.label-modal form {
      display: flex;
      label {
        padding: 7px 24px;
        background: #01af4e;
        border-radius: 5px;
        font-family: 'Raleway';
        font-size: 15px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        input {
          display: none;
        }
        & + label {
          margin-left: 10px;
          background: #001a69;
        }
      }
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        label {
          padding: 7px 10px;
          & + label {
            margin: 15px 0 0 0;
          }
        }
      }
    }

    @media (max-width: 768px) {
      > img {
        height: 78px;
        width: 150px;
      }
      span {
        width: 260px;
        & + span {
          width: 260px;
        }
      }
    }
  }
  footer {
    display: none;
    img.bolinhas {
      height: 9px;
      width: 60px;
    }
    a {
      text-decoration: none;
      color: #1c2d3c;
      font-family: 'Raleway';
      font-size: 13px;
      &:hover {
        color: ${shade(0.3, '#fff')};
      }
      b {
        font-size: 20px;
      }
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }
  }

  button.modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0;
    background: transparent;
  }
`;

import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  grid-template-columns: 70% 30%;

  display: grid;
  align-items: stretch;
  height: 100vh;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    display: grid;

    div.background {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 15px 5px;

  background-color: #e5e5e5;

  div.welcome-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    justify-content: end;
    padding-bottom: 0;
    > img {
      display: none;
    }
    div.welcome-mobile {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      > img {
        height: 9px;
        width: 60px;
        margin-bottom: 10px;
      }

      h1,
      span {
        color: #001a69;
      }
      h1 {
        margin-bottom: 30px;
      }
      span.span-welcome {
        font-size: 28px;
        margin-bottom: 10px;
      }
      span.span-text {
        color: #120702;
        margin: 10px 0 10px 0;
      }
    }
  }

  > img {
    height: 130px;
    width: 250px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    @media (max-width: 768px) {
      padding: 10px;
    }

    div.buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40%;

        border-radius: 7px;
        border: solid 1px #001a69;
        margin-top: 16px;

        color: #001a69;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;

        transition: color 0.2s;
        transition: background-color 0.2s;
        &:hover {
          background-color: #001a69;
          color: #fff;
          border: 2px solid #f4f4f4;
        }
      }
      @media (max-width: 768px) {
        button {
          width: 100px;
          height: 30px;
        }
        a {
          width: 150px;
          height: 30px;
        }
      }
    }
  }

  div.footer {
    width: 100%;
    padding: 0 40px;
    section.vetor {
      > img {
        display: none;
      }
    }
    section.plataforma {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      img {
        height: 9px;
        width: 60px;
      }
      span {
        font-size: 12px;
        color: #001a69;
        margin: 0 10px 0 70px;
      }
      a {
        display: none;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 10px;
      section.vetor {
        position: relative;
        > img {
          display: unset;
          width: 160px;
          height: 170px;
          position: absolute;
          bottom: 0;
        }
      }

      section.plataforma {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 60%;
        align-items: flex-end;

        a {
          display: unset;

          align-self: flex-end;
          margin-bottom: 16px;

          text-decoration: none;
          color: #1c2d3c;
          font-family: 'Raleway';
          &:hover {
            color: ${shade(0.3, '#fff')};
          }
          b {
            font-size: 25px;
          }
        }
        span {
          font-size: 10px;
          margin: 0;
        }
        img.conecta-mapa {
          width: 70px;
          height: 15px;
          margin-top: 5px;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 20px;

    > img {
      height: 9px;
      width: 60px;
      margin-bottom: 30px;
    }

    h1,
    span {
      color: #001a69;
    }
    span.span-welcome {
      font-size: 28px;
      margin-bottom: 30px;
    }
    span.span-text {
      color: #120702;
      margin: 10px 0 10px 0;
    }
  }
  footer {
    display: flex;
    width: 100%;
    height: 100%;
    > img {
      height: 350px;
      align-self: flex-end;
    }
    a {
      align-self: flex-end;
      text-decoration: none;
      margin: 30px auto;
      color: #1c2d3c;
      font-family: 'Raleway';
      &:hover {
        color: ${shade(0.3, '#fff')};
      }
      b {
        font-size: 25px;
      }
    }
  }

  /* > div {
    display: flex;
    flex-direction: column;
    align-items: center;



    img {
      margin: 0;
    }

  } */
`;
